import request from '@/utils/request'
// import { mockService } from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

// 获取待审核车场列表
export function getParklotList(params) {
    return request.post('/approve/getParklotList?' + objectToQueryString(params));
}

//获取待审核列表
export function getManualAuditList(params) {
    return request.post('/approve/getManualAuditList?' + objectToQueryString(params));
}

//获取人工审核记录
export function getAuditRecords(parkingOrderId) {
    return request.post('/operationAging/getAuditRecords?parkingOrderId=' + parkingOrderId);
}

//获取人工审核订单详情
export function getOrderDetailByOrderId(orderId, inspector) {
    return request.post('/approve/getOrderDetailByOrderId?orderId=' + orderId + '&inspector=' + inspector);
}

//取消订单
export function deleteManualAudit(params) {
    return request.post('/approve/deleteManualAudit?' + objectToQueryString(params));
}

//人工审核确认
export function confirmManualAudit(orderId, paringLotId) {
    return request.post('/approve/confirmManualAudit?orderId=' + orderId + '&paringLotId=' + paringLotId);
}

//下发巡检任务
export function updateManualAuditWithCheckNum(params) {
    return request.post('/approve/updateManualAuditWithCheckNum?' + objectToQueryString(params));
}

//修改车牌任务
export function updatePlateNum(params) {
    return request.post('/approve/updatePlateNum?' + objectToQueryString(params));
}

//获取下一条待审核记录
export function getNextOrderDetailByOrderId(orderId) {
    return request.post('/approve/getNextOrderDetailByOrderId?orderId=' + orderId);
}

//查询原因列表
export function getoperationall() {
    return request.get('/approve/getoperationall');
}
