<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="status">
          <el-select
              v-model="query.status"
              clearable
              placeholder="请选择订单状态"
              style="width: 260px"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="待审核" value="1"></el-option>
            <el-option label="已审核" value="2"></el-option>
            <!-- <el-option label="查车中" value="3"></el-option>
            <el-option label="搁置中" value="4"></el-option>
            <el-option label="已确认" value="5"></el-option>
            <el-option label="已取消" value="6"></el-option>
            <el-option label="OCR识别中" value="7"></el-option> -->
          </el-select>
        </el-form-item>

        <el-form-item prop="parkingOrderCode">
          <el-input
              v-model="query.parkingOrderCode"
              clearable
              placeholder="请输入订单编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>

        <el-form-item prop="parkingSpaceCode">
          <el-input
              v-model="query.parkingSpaceCode"
              clearable
              placeholder="请输入泊位号"
              style="width: 260px"
          ></el-input>
        </el-form-item>

        <el-form-item prop="vehicleId">
          <NumplateSuggest v-model="query.vehicleId" style="width: 260px"/>
          <!-- <el-input
            v-model="query.vehicleId"
            placeholder="请输入车牌号"
            clearable
            style="width: 260px"
          ></el-input> -->
        </el-form-item>

        <el-form-item prop="handlerUserId">
          <el-input
              v-model="query.handlerUserId"
              clearable
              placeholder="请输入操作人名称"
              style="width: 260px"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item prop="inspectorInvalidType">
          <el-select
            clearable
            v-model="query.inspectorInvalidType"
            placeholder="请选择无法查补订单类型"
            style="width: 260px"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="任务无法查补" value="1"></el-option>
            <el-option label="自主无法查补" value="2"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item prop="authenticationTime">
          <div class="xxd-input__wrap">
            <span>提交时间</span>
            <el-date-picker
                v-model="query.submitTime"
                align="right"
                end-placeholder="结束时间"
                range-separator="~"
                start-placeholder="开始时间"
                type="daterange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item prop="authenticationTime">
          <div class="xxd-input__wrap">
            <span>审核时间</span>
            <el-date-picker
                v-model="query.examineTime"
                align="right"
                end-placeholder="结束时间"
                range-separator="~"
                start-placeholder="开始时间"
                type="daterange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="doReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          <span style="margin-right: 20px"
          >当前查看：{{ provinceName }}-{{ cityName }}-{{ areaName }} {{ parklotName }}</span
          >共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
<!--          <icon-button icon="share" title="导出" @click.native="exportData"/>-->
          <icon-button slot="extra" icon="return" @click.native="goBack"/>
        </div>
      </div>

      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column align="center" label="订单号码" prop="parkingOrderCode" width="240">
        </el-table-column>
        <el-table-column align="center" label="提交时间" prop="intoTime" width="240">
        </el-table-column>
        <el-table-column align="center" label="泊位号" prop="parkSpaceCode" width="240">
        </el-table-column>
        <el-table-column align="center" label="停车信息">
          <el-table-column align="center" label="车牌号" width="240">
            <template slot-scope="scope">
              <Numplate :type="scope.row.vehicleColor">{{
                  scope.row.numberPlate
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column align="center" label="驶入时间" prop="intoTime" width="240">
          </el-table-column>
          <el-table-column align="center" label="驶出时间" prop="driveTime" width="240">
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="订单来源" prop="sourceType" width="240">
        </el-table-column>
        <el-table-column align="center" label="操作人" prop="handlerUserName" width="240">
        </el-table-column>
        <el-table-column align="center" label="审核时间" prop="reviewTime" width="240">
        </el-table-column>
        <el-table-column align="center" label="状态" prop="operationStatus" width="240">
          <template slot-scope="scope">
            {{ operationStatus[scope.row.operationStatus] }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="100"
        >
          <template slot-scope="scope">
            <el-button
                size="small"
                style="font-size: 14px"
                type="text"
                @click="doHandle(scope.row)"
            >点击处理
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.page"
            :page-size="pagination.size"
            :page-sizes="[10, 15, 30, 50]"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import {isEmpty} from "@/utils/utils";
import {getManualAuditList} from "@/api/manualReview";
import NumplateSuggest from "@/components/NumplateSuggest";
import Numplate from "@/components/Numplate";
import {doExport} from "@/api/common";

export default {
  name: "parkinglot_detail",
  mixins: [BaseMixin],
  components: {
    Numplate,
    NumplateSuggest
  },
  data() {
    return {
      parklotName: "",
      // parkingLotId: "",
      provinceName: "",
      cityName: "",
      areaName: "",
      query: {
        status: "1",
        parkingOrderCode: "",
        parkingSpaceCode: "",
        vehicleId: "",
        handlerUserId: "",
        inspectorInvalidType: "",
        submitTime: [],
        startDate: "",
        endDate: "",
        examineTime: [],
        operationstartDate: "",
        operationendDate: "",
        parkingLotId: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      isLoading: false,
      dataList: [],
      operationStatus: {
        0: "正常",
        1: "人工审核中",
        2: "修改车牌",
        3: "查车牌",
        9: "人工审核完成"
      },
    };
  },
  methods: {
    //返回车场列表页
    goBack() {
      this.$router.back();
    },
    //重置
    doReset() {
      this.$refs.query.resetFields();
      this.query.submitTime = [];
      this.query.examineTime = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      const params = this.paramFormat(this.query);
      if (params.parkingLotId === "") {
        return false
      }
      if (!isEmpty(params.submitTime)) {
        params.startDate = params.submitTime[0];
        params.endDate = params.submitTime[1];
      }
      if (!isEmpty(params.examineTime)) {
        params.operationstartDate = params.examineTime[0];
        params.operationendDate = params.examineTime[1];
      }
      this.isLoading = true;
      const res = await getManualAuditList(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        this.dataList = res.returnObject.list;
        this.pagination.totalSize = res.returnObject.count;
        this.pagination.totalPages = res.returnObject.totalPage;
        this.form.exportSize = res.returnObject.count;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (params.parkingLotId === "") {
        return false
      }
      if (!isEmpty(params.submitTime)) {
        params.startDate = params.submitTime[0];
        params.endDate = params.submitTime[1];
      }
      if (!isEmpty(params.examineTime)) {
        params.operationstartDate = params.examineTime[0];
        params.operationendDate = params.examineTime[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport("/approve/getManualAuditList", params, "人工审核.xlsx");
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //点击处理
    doHandle(e) {
      this.$router.push({
        path: "/operation_manage/manual_review/order_processing",
        query: {
          parkingOrderId: e.parkingOrderId,
          inspectorId: e.inspectorId,
        }
      })
    },
  },
  mounted() {
    this.parklotName = this.$route.query.parklotName;
    this.query.parkingLotId = this.$route.query.parkingLotId;
    this.provinceName = this.$route.query.provinceName;
    this.cityName = this.$route.query.cityName;
    this.areaName = this.$route.query.areaName;
    this.search();
  }
};
</script>

<style lang="less" scoped>
</style>
